import filter from 'lodash.filter'
import uniq from 'lodash.uniq'
import uniqBy from 'lodash.uniqby'
import { useRouter } from 'next/router'
import { decode } from 'qss'
import { useEffect, useState } from 'react'

import useUser from './useUser'

export const useCampusFilter = (entries) => {
  const { data, isValidating, userCampus } = useUser()
  const [selectedCampus, setSelectedCampus] = useState('alle')
  const [filteredEntries, setFilteredEntries] = useState(entries)
  const [filterButtons, setFilterButtons] = useState(entries)
  const router = useRouter()

  const makeFilter = (params: { campus: string }) => {
    let campus = []

    if (selectedCampus !== 'alle') {
      campus.push({ slug: params.campus })
    }
    return { campus }
  }

  useEffect(() => {
    let arr = []
    entries.map((entry) => {
      arr.push(...entry.campus.map((campus) => campus))
    })
    setFilterButtons(uniqBy(arr, 'uid'))
  }, [entries])

  useEffect(() => {
    let query = `campus=alle`
    if (router.asPath.split('?')[1]) {
      query = router.asPath.split('?')[1]
    } else {
      if (userCampus) {
        if (filterButtons.find((button) => button.slug === userCampus.slug)) {
          query = `campus=${userCampus?.slug}`
        }
      }
    }
    const params: any = decode(query)
    setSelectedCampus(params.campus)

    let favs = []
    if (data && data.favorites && data.favorites.length !== 0) {
      favs = entries?.filter((child) => {
        const fav = data?.favorites.find((f) => f.uid === child.uid)
        return fav
      })
    }

    const arr = filter(entries, makeFilter(params))
    return setFilteredEntries(uniq([...favs, ...arr]))
    // return setFilteredEntries(arr)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath, selectedCampus, userCampus, data])

  return {
    filteredEntries,
    selectedCampus,
    setSelectedCampus,
    setFilteredEntries,
    isValidating,
    filterButtons,
  }
}
