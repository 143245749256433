import { Button } from '@chakra-ui/react'
import RouterLink from 'next/link'

const FilterButton = ({
  children,
  href,
  isSelected,
}: {
  children: any
  slug?: string
  href?: string
  isSelected?: boolean
}) => {
  return (
    <RouterLink href={href} passHref legacyBehavior>
      <Button flex={'none'} variant={'pill'} as={'a'} isActive={isSelected}>
        {children}
      </Button>
    </RouterLink>
  )
}

export default FilterButton
