import { Alert, AlertTitle } from '@chakra-ui/react'

const NoHitsList = ({ children }: Props) => {
  return (
    <Alert status="error" px={[3, 6]} py={[3, 6]}>
      <AlertTitle fontWeight={'medium'} fontSize={['md', 'xl']}>
        {children}
      </AlertTitle>
    </Alert>
  )
}

type Props = { children: string }

export default NoHitsList
