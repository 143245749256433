import React from 'react'
const Price = ({ children }: Props) => {
  if (!children && children !== 0) return <>Feil i pris</>

  return (
    <>
      {Intl.NumberFormat('nb-NO', {
        style: 'currency',
        currency: 'NOK',
        minimumFractionDigits: 2,
      }).format(children)}
    </>
  )
}

type Props = {
  children: number
}

export default Price
