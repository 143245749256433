import { Box, Flex, Heading, Icon } from '@chakra-ui/react'
import FilterButton from 'components/FilterButton'
import GridContainer from 'components/GridContainer'
import GridItem from 'components/GridItem'
import { useCampusFilter } from 'hooks/useCampusFilter'
import l10n from 'l10n'
import { SlidersHorizontal } from 'lucide-react'
import { Entry } from 'types'
import BreadcrumbBar from './BreadcrumbBar'
import Intro from './Intro'
import ListCard from './ListCard'
import NoHitsList from './NoHitsList'

const EntryList = ({ entry }: Props) => {
  const { filteredEntries, selectedCampus, filterButtons } = useCampusFilter(entry.children)

  if (!entry) return <></>

  return (
    <>
      <GridContainer mt={[24, 28]}>
        <GridItem colStart={1} colSpan={12}>
          <BreadcrumbBar parent={entry?.parent} title={entry?.title} />
        </GridItem>
      </GridContainer>
      <GridContainer mt={[4, 6]}>
        <GridItem colStart={1} colSpan={12}>
          <Heading as="h1" fontSize={['3xl', '4xl', '5xl']}>
            {entry?.title}
          </Heading>
          <Intro mt={6}>{entry?.intro}</Intro>
        </GridItem>
      </GridContainer>
      <Box
        position={'sticky'}
        top={['56px']}
        zIndex={1000}
        mt={[2, 4]}
        // boxShadow="rgb(0 0 0 / 16%) 0 0 6px"
      >
        <GridContainer px={0}>
          <GridItem colSpan={12} colStart={1}>
            <Flex
              py={3}
              px={[3, 10]}
              alignItems={'center'}
              flexWrap={[null, 'wrap']}
              whiteSpace="nowrap"
              gap={2}
              overflow={'hidden'}
              overflowX={'scroll'}
              backgroundColor={'rgba(255,255,255,0.92)'}
              backdropFilter="blur(5px)"
              // boxShadow="rgb(0 0 0 / 16%) 0 0 6px"
              zIndex={'900'}
              sx={{
                '::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              <Icon as={SlidersHorizontal} width={5} height={5} color="gray.400"></Icon>
              <FilterButton
                href={`${entry.url}?campus=alle`}
                isSelected={selectedCampus === 'alle'}
              >
                {l10n.all}
              </FilterButton>
              {filterButtons.length !== 0 &&
                filterButtons?.map((campus) => (
                  <FilterButton
                    key={campus.uid}
                    href={`${entry.url}?campus=${campus.slug}`}
                    isSelected={selectedCampus === campus.slug}
                  >
                    {campus.title}
                  </FilterButton>
                ))}
            </Flex>
          </GridItem>
        </GridContainer>
      </Box>
      <GridContainer rowGap={[6]} mt={[6, 8]} mb={[8, 16]}>
        {!filteredEntries.length && (
          <GridItem colSpan={12} colStart={null}>
            <NoHitsList>{l10n.noHits}</NoHitsList>
          </GridItem>
        )}
        {filteredEntries?.map((child) => (
          <ListCard key={child.id} entry={child} />
        ))}
      </GridContainer>
    </>
  )
}

type Props = {
  entry: Entry
}

export default EntryList
