import { Flex, Button, Text } from '@chakra-ui/react'
import ArticleCard from 'components/ArticleCard'
import BlocksPageBuilder from 'components/BlocksPageBuilder'
import EntryEatery from 'components/EntryEatery'
import EntryList from 'components/EntryList'
import EntryMarketing from 'components/EntryMarketing'
import EntryPages from 'components/EntryPages'
import EntryService from 'components/EntryService'
import GridContainer from 'components/GridContainer'
import GridItem from 'components/GridItem'
import PageFooter from 'components/PageFooter'
import l10n from 'l10n'
import BaseLayout from 'layouts/BaseLayout'
import { ArrowRight } from 'lucide-react'
import GET_PAGE from 'queries/page'
import { Campus as CampusType, ContactInfo, Entry, FooterMenu, MainMenu, Semomatic } from 'types'
import { fetcher } from 'utils/cmsDataFetcher'
import constants from 'utils/constants'
import { getSiteHandle } from 'utils/i18nUtils'

const Page = ({ data, site }: Props) => {
  return (
    <BaseLayout
      title={data.entry?.title}
      footerMenu={data?.footerMenu}
      mainMenu={data?.mainMenu}
      alerts={data?.alerts}
      contactInfo={data?.contactInfo}
      headerBackgroundColor={'white'}
      {...data?.entry?.seomatic}
    >
      {data?.entry?.typeHandle === constants.EntryTypes.Service && (
        <>
          <EntryService entry={data?.entry} />
          <BlocksPageBuilder blocks={data.entry?.pageBuilder} />
          {!!data?.entry?.newsFromCategory?.length && (
            <GridContainer mt={[32]} rowGap={[4, 6]}>
              <GridItem colSpan={12} colStart={1}>
                <Flex justifyContent={'space-between'} alignItems="center">
                  <Text fontWeight={'medium'} fontSize={['md', 'xl']}>
                    {l10n.moreFromArticles}
                  </Text>
                  <Button
                    as="a"
                    href="/aktuelt"
                    size={['xs', 'sm']}
                    colorScheme={'dark'}
                    variant="outline"
                    rightIcon={<ArrowRight size={16} />}
                  >
                    {l10n.seeAllArticles}
                  </Button>
                </Flex>
              </GridItem>
              {data?.entry?.newsFromCategory[0]?.relatedArticles?.map((article) => {
                return (
                  <GridItem key={article.uid} colSpan={[6, 4, 3]} colStart={[null]}>
                    {/* @ts-ignore */}
                    <ArticleCard {...article} />
                  </GridItem>
                )
              })}
            </GridContainer>
          )}
        </>
      )}

      {data?.entry?.typeHandle === constants.EntryTypes.Pages && (
        <>
          <EntryPages entry={data?.entry} />
          <BlocksPageBuilder blocks={data.entry?.pageBuilder} />
          {!data?.entry?.children?.length && <PageFooter>{data?.entry?.dateUpdated}</PageFooter>}
          {!!data?.entry?.newsFromCategory?.length && (
            <GridContainer mt={[32]} rowGap={[4, 6]}>
              <GridItem colSpan={12} colStart={1}>
                <Flex justifyContent={'space-between'} alignItems="center">
                  <Text fontWeight={'medium'} fontSize={['md', 'xl']}>
                    {l10n.moreFromArticles}
                  </Text>
                  <Button
                    as="a"
                    href="/aktuelt"
                    size={['xs', 'sm']}
                    colorScheme={'dark'}
                    variant="outline"
                    rightIcon={<ArrowRight size={16} />}
                  >
                    {l10n.seeAllArticles}
                  </Button>
                </Flex>
              </GridItem>
              {data?.entry?.newsFromCategory[0]?.relatedArticles?.map((article) => {
                return (
                  <GridItem key={article.uid} colSpan={[6, 4, 3]} colStart={[null]}>
                    {/* @ts-ignore */}
                    <ArticleCard {...article} />
                  </GridItem>
                )
              })}
            </GridContainer>
          )}
        </>
      )}

      {data?.entry?.typeHandle === constants.EntryTypes.Event && (
        <>
          <EntryPages entry={data?.entry} />
          <BlocksPageBuilder blocks={data.entry?.pageBuilder} />
        </>
      )}

      {data?.entry?.typeHandle === constants.EntryTypes.List && (
        <>
          <EntryList entry={data?.entry} />
          <BlocksPageBuilder blocks={data.entry?.pageBuilder} />
        </>
      )}

      {data?.entry?.typeHandle === constants.EntryTypes.Marketing && (
        <>
          <EntryMarketing entry={data?.entry} />
          <BlocksPageBuilder blocks={data.entry?.pageBuilder} />
        </>
      )}

      {data?.entry?.typeHandle === constants.EntryTypes.Eatery && (
        <>
          <EntryEatery entry={data?.entry} blocks={data.entry?.pageBuilder} />
        </>
      )}
    </BaseLayout>
  )
}

export async function getStaticPaths() {
  const GET_ENTRIES = `
    query getPages($site: [String]) {
      entries(section: "pages", site: $site, status: "live", limit: 100, orderBy: "postDate desc") {
        uri
        locale: language
      }
    }
  `
  const data: any = await fetcher(GET_ENTRIES, null, {
    site: [constants.Sites.Norwegian, constants.Sites.English],
  })

  const params = data?.entries.map((entry) => {
    const arr = entry.uri.split('/')
    return { params: { uri: arr }, locale: entry.locale }
  })

  return {
    paths: [...params],
    fallback: 'blocking',
  }
}

export async function getStaticProps({ params, locale }) {
  const site = getSiteHandle(locale)
  const uri = params.uri.join('/')
  const data = await fetcher(GET_PAGE, null, {
    uri,
    site,
  })

  if (!data) {
    return {
      notFound: true,
    }
  }

  return {
    props: { data, site },
    revalidate: 600,
    notFound: !data?.entry,
  }
}

export type Props = {
  site: string
  data: {
    entry: Entry
    campus: [CampusType]
    mainMenu?: MainMenu
    footerMenu?: FooterMenu
    alerts?: any
    contactInfo?: ContactInfo
    seomatic: Semomatic
  }
}

export default Page
