import { Box } from '@chakra-ui/react'
import GridContainer from 'components/GridContainer'
import GridItem from 'components/GridItem'
import NavigationListLink from 'components/NavigationListLink'
import Image from 'next/image'
import { CallToAction, Child, MainImage, MenuItems } from 'types'
import HeadingWithColor from './HeadingWithColor'
import Intro from './Intro'

const EntryService = ({ entry }: Props) => {
  return (
    <>
      {entry?.mainImage[0] && (
        <Box position="relative" height={['40vh', '60vh']} width={'100%'}>
          <Image
            src={entry?.mainImage[0]?.path}
            alt={entry?.mainImage[0]?.title}
            placeholder="blur"
            blurDataURL={entry?.mainImage[0]?.imageOptimize?.placeholderImage}
            fill
            style={{ objectFit: 'cover' }}
            priority
            sizes="100vw"
          ></Image>
        </Box>
      )}
      <GridContainer mt={[6, 8]}>
        <GridItem colStart={1} colSpan={12}>
          <HeadingWithColor>{entry?.headingWithColor}</HeadingWithColor>
          <Intro mt={6}>{entry?.intro}</Intro>
        </GridItem>
      </GridContainer>

      <GridContainer rowGap={[4, 8]} mt={[8, 16]}>
        {entry?.callToActions?.map((link) => {
          return (
            <GridItem key={link.uid} colSpan={[12, 12, 6, 4]} colStart={1}>
              <NavigationListLink
                colorTheme="orange"
                href={link.ctaLink.url}
                title={link.ctaLink.text}
                description={link.description}
              ></NavigationListLink>
            </GridItem>
          )
        })}
      </GridContainer>

      {!!entry?.children?.length && (
        <GridContainer rowGap={[4, 8]} mt={[4, 8]} mb={[8, 16]}>
          {entry?.children?.map((child) => {
            if (child.hideFromNavigationList) return null
            return (
              <GridItem key={child.id} colSpan={[12, 12, 6, 4]} colStart={1}>
                <NavigationListLink
                  title={child.title}
                  description={child.intro}
                  href={child.uri}
                ></NavigationListLink>
              </GridItem>
            )
          })}
        </GridContainer>
      )}
    </>
  )
}

type Props = {
  entry: {
    id: string
    title: string
    url: string
    uri: string
    intro: string
    dateUpdated: string
    headingWithColor: string
    colorTheme: string
    callToActions?: CallToAction[]
    menuItems: [MenuItems]
    mainImage: MainImage
    children: [Child]
  }
}

export default EntryService
