import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import { css } from '@emotion/react'
import GridContainer from 'components/GridContainer'
import GridItem from 'components/GridItem'
import { Child, MainImage, Parent, Dish as DishType } from 'types'
import AddAsFavorite from './AddAsFavorite'
import BlocksPageBuilder from './BlocksPageBuilder'
import BreadcrumbBar from './BreadcrumbBar'
import Intro from './Intro'
import RichText from './RichText'
import Dish from './Dish'

const CalloutModule = ({ heading, text, contactInfo }: Callout) => (
  <Flex flexDir="column" px={[3, 6]} py={[3, 6]} backgroundColor="red.100">
    <Text as={'h3'} fontWeight={'medium'} fontSize={['md', 'xl']} color="red.600">
      {heading}
    </Text>
    <RichText>{text}</RichText>
    <RichText>{contactInfo}</RichText>
  </Flex>
)

const EntryEatery = ({ entry, blocks }: Props) => {
  if (!entry) return <></>

  return (
    <>
      <GridContainer mt={[24, 28]}>
        <GridItem>
          <BreadcrumbBar parent={entry?.parent} title={entry?.title} />
        </GridItem>
      </GridContainer>
      <GridContainer mt={[6, 8]}>
        <GridItem>
          <Heading as="h1" fontSize={['3xl', '4xl', '5xl']}>
            {entry?.title}
          </Heading>
          <Intro mt={6}>{entry?.intro}</Intro>
          <Box mt={4}>
            {entry.openingHours?.map((row) => (
              <Text key={row.day} fontSize={['sm', 'md']} fontWeight="medium">
                {row.day}:
                <Text as="span" fontSize={['sm', 'md']} fontWeight="normal" ml={1}>
                  {row.hours}
                </Text>
              </Text>
            ))}
          </Box>
          {!!!entry?.children?.length && (
            <Flex alignItems="center" mt={[6, 8]}>
              <AddAsFavorite entry={entry} type={entry?.typeHandle} />
            </Flex>
          )}
        </GridItem>
      </GridContainer>
      {entry.callout.length > 0 && (
        <GridContainer mt={[6, 8]}>
          <GridItem>
            <Box
              display={['flex', 'flex', 'grid']}
              maxWidth="7xl"
              mx="auto"
              overflowX="scroll"
              css={css`
                scroll-snap-type: x mandatory;
                scroll-behavior: smooth;
                repeat: (80vw);
                grid-template-columns: repeat(${entry.callout.length > 1 ? '2' : '1'}, 1fr);
                column-gap: 1rem;
              `}
            >
              {entry.callout?.map((c) => {
                const itemWidth = entry.callout.length > 1 ? '80vw' : 'calc(100% - 1.5rem)'
                return (
                  <Box
                    key={c.uid}
                    minWidth={[itemWidth, itemWidth, 'auto']}
                    css={css`
                      scroll-snap-align: start;
                      grid-column: span 1;
                    `}
                  >
                    <CalloutModule heading={c.heading} text={c.text} contactInfo={c.contactInfo} />
                  </Box>
                )
              })}
            </Box>
          </GridItem>
        </GridContainer>
      )}
      <BlocksPageBuilder blocks={blocks} />
      <GridContainer rowGap={[8, 12]}>
        <GridItem>
          {/* <Text fontWeight={'medium'} fontSize={['md', 'lg']}>
            Eksempel på meny
          </Text> */}
          {entry?.eateryMenuItems?.map((dish, index) => (
            <Dish index={index} key={dish.uid} dish={dish} />
          ))}
        </GridItem>
      </GridContainer>
    </>
  )
}

type Callout = {
  heading: string
  text: string
  contactInfo: string
  uid?: string
}

type Props = {
  blocks: any
  entry: {
    id: string
    title: string
    uri: string
    url: string
    intro: string
    callout?: Callout[]
    dateUpdated: string
    typeHandle: string
    eateryMenuItems?: DishType[]
    openingHours: [
      {
        day: string
        hours: string
      }
    ]
    mainImage: MainImage
    children: Child[]
    parent: Parent
  }
}

export default EntryEatery
