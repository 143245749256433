import { Flex, Image } from '@chakra-ui/react'
import React from 'react'

const Diets = ({ children }: any) => {
  if (!children) return <></>

  return (
    <Flex gap={1}>
      {children?.map((diet) => (
        <Flex key={diet.uid} alignItems="center" gap={1} fontSize={['xs', 'sm']}>
          {diet.mainImage[0]?.url && (
            <Image src={diet.mainImage[0]?.url} alt={diet.title} width={5} height={5} />
          )}
          {diet.title}
        </Flex>
      ))}
    </Flex>
  )
}

export default Diets
