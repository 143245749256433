import { Box } from '@chakra-ui/react'
import GridContainer from 'components/GridContainer'
import GridItem from 'components/GridItem'
import Image from 'next/image'
import { MainImage, Parent } from 'types'
import BreadcrumbBar from './BreadcrumbBar'
import HeadingWithColor from './HeadingWithColor'
import Intro from './Intro'

const EntryMarketing = ({ entry }: Props) => {
  return (
    <>
      <GridContainer mt={[24, 28]}>
        <GridItem>
          <BreadcrumbBar parent={entry?.parent} title={entry?.title} />
        </GridItem>
      </GridContainer>
      <GridContainer mt={[4, 6]} rowGap={8}>
        <GridItem>
          <HeadingWithColor>{entry?.headingWithColor}</HeadingWithColor>
          <Intro mt={6}>{entry?.intro}</Intro>
        </GridItem>
        {entry?.mainImage[0] && (
          <GridItem colStart={[1, null, null, 3]} colSpan={[12, null, null, 8]}>
            <Image
              src={entry?.mainImage[0]?.path}
              alt={entry?.mainImage[0]?.title}
              width={entry?.mainImage[0]?.width}
              height={entry?.mainImage[0]?.height}
              placeholder={entry?.mainImage[0]?.imageOptimize?.placeholderImage ? 'blur' : 'empty'}
              blurDataURL={entry?.mainImage[0]?.imageOptimize?.placeholderImage}
              sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
            />
          </GridItem>
        )}
      </GridContainer>
    </>
  )
}

type Props = {
  entry: {
    id: string
    title: string
    url: string
    uri: string
    intro: string
    headingWithColor: string
    mainImage: MainImage
    parent: Parent
  }
}

export default EntryMarketing
