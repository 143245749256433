import * as React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

const Intro = ({ fontSize = ['lg', null, 'xl'], children, ...rest }: TextProps) => {
  if (!children) return null
  return (
    <Text fontSize={fontSize} {...rest}>
      {children}
    </Text>
  )
}

export default Intro
