import * as React from 'react'
import { Divider, Flex, Icon, Link, Text } from '@chakra-ui/react'
import Date from 'components/Date'
import l10n from 'l10n'
import GridContainer from './GridContainer'
import GridItem from './GridItem'
import { ArrowUp } from 'lucide-react'

const PageFooter = ({ children }: Props) => {
  return (
    <GridContainer mt={[8, 16]}>
      <GridItem>
        <Divider />
        <Flex justifyContent={'space-between'} alignItems="center" mt={[3, 6]}>
          <Text fontSize={['sm', 'md']} color="gray.600">
            {l10n.updated} <Date>{children}</Date>
          </Text>
          <Flex as={Link} alignItems="center" gap={1} href="#top">
            <Icon as={ArrowUp}></Icon>
            <Text fontSize={['sm', 'md']} color="gray.600">
              Til toppen
            </Text>
          </Flex>
        </Flex>
      </GridItem>
    </GridContainer>
  )
}

type Props = {
  children: string
}

export default PageFooter
