import { Heading } from '@chakra-ui/react'
import GridContainer from 'components/GridContainer'
import GridItem from 'components/GridItem'
import NavigationListLink from 'components/NavigationListLink'
import { Child, Parent } from 'types'
import BreadcrumbBar from './BreadcrumbBar'
import Intro from './Intro'

const EntryPages = ({ entry }: Props) => {
  const hasChildren = entry?.children?.length > 0
  const hasMoreThanThreeChildren = entry?.children?.length > 2

  return (
    <>
      <GridContainer mt={[24, 28]}>
        <GridItem
          colStart={hasMoreThanThreeChildren ? 1 : { lg: 3, xl: 4 }}
          colSpan={{ base: 12, lg: 8, xl: 6 }}
        >
          <BreadcrumbBar parent={entry?.parent} title={entry?.title} />
        </GridItem>
      </GridContainer>
      <GridContainer mt={[6, 8]}>
        <GridItem
          colStart={hasMoreThanThreeChildren ? 1 : { lg: 3, xl: 4 }}
          colSpan={{ base: 12, lg: 8, xl: 6 }}
        >
          <Heading as="h1" fontSize={['3xl', '4xl', '5xl']}>
            {entry?.title}
          </Heading>
          <Intro mt={6}>{entry?.lead}</Intro>
        </GridItem>
      </GridContainer>
      {hasChildren && (
        <GridContainer rowGap={[4, 8]} mt={[6, 8]}>
          {entry?.children?.map((child, index) => {
            if (child.hideFromNavigationList) return null
            let colStart = [null, null, null, (index + 1) * 3 + index, (index + 1) * 4 - index]
            let colSpan = [12, 6, 6, 4, 3]
            if (entry?.children?.length > 2) {
              colStart = [null, null, null, null, null]
              colSpan = [12, 6, 6, 4, 4]
            }
            return (
              <GridItem key={child.id} colSpan={colSpan} colStart={colStart}>
                <NavigationListLink
                  href={child.uri}
                  title={child.title}
                  description={child.intro}
                ></NavigationListLink>
              </GridItem>
            )
          })}
        </GridContainer>
      )}
    </>
  )
}

type Props = {
  entry: {
    id: string
    title: string
    url: string
    uri: string
    intro: string
    lead: string
    dateUpdated: string
    children: [Child]
    parent: Parent
  }
}

export default EntryPages
