import * as React from 'react'
import GridItem from './GridItem'
import ResponsiveImage from './ResponsiveImage'
import Allergens from './Allergens'
import Diets from './Diets'
import { Box, Flex, Tag, Text } from '@chakra-ui/react'
import Price from './Price'
import { Dish as DishType } from 'types'
import ChakraNextImage from './ChakraNextImage'
import l10n from 'l10n'

const SpecialBadge = () => (
  <Tag
    width={'fit-content'}
    colorScheme={'blue'}
    fontSize="xs"
    textTransform={'uppercase'}
    letterSpacing={'wide'}
  >
    {l10n.special}
  </Tag>
)

const Dish = ({ special, dish, index }: Props) => {
  const backgroundColor = index % 2 ? 'white' : 'gray.50'
  const backgroundColorSpecial = 'blue.50'
  const hasImage = dish?.mainImage?.[0]
  return (
    <Box key={dish.uid} mt={index === 0 && [2, 4]}>
      <Flex
        p={[4, 6]}
        gap={[1, 4]}
        flexDirection={['column', 'row']}
        justifyContent="space-between"
        backgroundColor={special ? backgroundColorSpecial : backgroundColor}
      >
        {hasImage && (
          <Box
            position={'relative'}
            width={'85px'}
            height={'85px'}
            display={['none', 'block']}
            borderRadius="xl"
            flex={'none'}
          >
            <ChakraNextImage
              // @ts-ignore
              fill
              borderRadius="xl"
              objectFit={'cover'}
              src={dish?.mainImage?.[0]?.path}
              alt={dish?.mainImage?.[0]?.title}
              sizes="100vw"
            />
          </Box>
        )}
        <Flex flexDirection={'column'} gap={1}>
          {special && !hasImage && <SpecialBadge />}
          <Text fontWeight={'medium'} fontSize={['md', 'lg']}>
            {dish.title}
          </Text>
          <Text fontSize={['sm', 'md']}>{dish.description}</Text>
          <Allergens>{dish.allergens}</Allergens>
          <Diets>{dish.diets}</Diets>
        </Flex>
        <Flex flexDirection={'column'} gap={1} textAlign={['left', 'right']} flex="none">
          <Text fontWeight={'medium'} fontSize={['md', 'xl']}>
            <Price>{dish.studentPrice}</Price>
            {dish.unit && dish.unit !== 'stk' && (
              <Text fontSize={['xs', 'sm']} as="span">
                {' '}
                / {dish.unit}
              </Text>
            )}
          </Text>
          {dish.studentPrice && (
            <Text fontSize={['xs', 'sm']}>
              {l10n.nonStudent} <Price>{dish.regularPrice}</Price>
              {dish.unit && dish.unit !== 'stk' && (
                <Text fontSize={['xs', 'sm']} as="span">
                  {' '}
                  / {dish.unit}
                </Text>
              )}
            </Text>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

type Props = {
  dish: DishType
  special?: boolean
  index: number
}

export default Dish
