import { Flex, Text } from '@chakra-ui/react'
import React from 'react'

const Allergens = ({ children }: any) => {
  if (!children) return <></>

  return (
    <>
      <Flex fontSize={['xs', 'sm']} gap={1} flexWrap="wrap">
        <Text fontSize={['xs', 'sm']} as="span">
          Allergener:
        </Text>
        {children?.map((allergen, index) => (
          <Text as="span" key={allergen.allergen}>
            {allergen.allergen}
            {index === children.length - 1 ? '' : ','}
          </Text>
        ))}
      </Flex>
    </>
  )
}

export default Allergens
