import { Flex, Icon, Link } from '@chakra-ui/react'
import { ArrowLeft } from 'lucide-react'
import RouterLink from 'next/link'

const BreadcrumbBar = ({ parent }: Props) => {
  return (
    parent && (
      <Flex alignItems={'center'} gap={2}>
        <Icon as={ArrowLeft} color="orange.400" width={5} height={5} />
        <RouterLink href={parent?.uri} passHref legacyBehavior>
          <Link>{parent?.title}</Link>
        </RouterLink>
      </Flex>
    )
  )
}

type Props = {
  color?: string
  title?: string
  children?: any
  parent?: {
    title: string
    url?: string
    uri?: string
  }
}

export default BreadcrumbBar
